import { ref } from 'vue'
import ax from 'axios'
export const axios = ax

const deadlineDaysDefault = 14;
const deadlineDaysMin = 2;

const global = ref({
    companyCreate: {
        step: 1,
        //stepDataIsValid: false, // Is data of actual step filled in validly?
        data: {
            user: {
                emailAvailable: true
            },
            company: {
                competencesSelected: [],
                otherCompetences: {},
                otherCompetencesMaxLength: 30,
                vatAvailable: true
            },
            contact: {},
            order: null
        },
        promoCode: {},
        priceNormal: 600,
    },
    content: {
        pages: []
    },
    task: {
        company: {
            anonymous: false
        },
        contact: {},
        uploads: [],
        //mailRecipients: [],
        isSubmitable: false,
        showSubmitReceipt: false,
        deadlineDaysMin: deadlineDaysMin,
        deadline: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000 + deadlineDaysDefault*24*60*60*1000).toISOString().substr(0, 10),
        deadlineMin: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000 + deadlineDaysMin*24*60*60*1000).toISOString().substr(0, 10),
        submittedTasks: []
    },
    favoritesConsultants: {},
    competencesSelected: [],
    consultantsFound: [],
    mode: {
        header: ''
    },
    descriptionMaxLength: 500
});

export const useComposition = function() {
    return {
        global
    }
}

/*
export const useWeights = function() {
    axios.get(process.env.VUE_APP_API_ROOT + '/tasks?mode=get&id=2&userAlias=' + getCookie('userAlias'))
        .then(response => {
            console.log( response.data);
            //console.log('taskKeepDraft()', mode, id);

            return {
                data: response.data
            }
        })
    
}
*/