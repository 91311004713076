<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'
import ax from 'axios'
export const axios = ax

export default defineComponent({
  setup() {
    const { global } = useComposition();

    return {
      global
    }
  },
  created() {

    /*
      fetch(process.env.VUE_APP_API_ROOT + '/_testCookie?value=' + getParameterByName('value'), { credentials: 'include' })
        .then(response => response.json())
        .then(data => {

          console.log(data);
          fetch(process.env.VUE_APP_API_ROOT + '/_testCookie', { credentials: 'include' })
            .then(response => response.json())
            .then(data => {

              console.log(data);
            });
        
        });
    
      axios.get(process.env.VUE_APP_API_ROOT + '/_testCookie?value=' + getParameterByName('value'), { withCredentials: true })
          .then(response => {
              console.log(response.data);

              axios.get(process.env.VUE_APP_API_ROOT + '/_testCookie', { withCredentials: true })
                .then(response => {
                    console.log(response.data);
                })
          })
      */

    this.loadContent();
    //this.global.favoritesConsultants = getCookie('favoritesConsultants') == null ? {} : JSON.parse(getCookie('favoritesConsultants'));
  },
  methods: {
    loadContent() {
      axios.get(process.env.VUE_APP_API_ROOT + '/content')
        .then(response => {
            this.global.content = response.data;
        }) 
    }    
  }
})
</script>

<style lang="scss">
  @import "./_scss/main.scss";
</style>