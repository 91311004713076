import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Page.vue')
    },
    {
        path: '/:url',
        name: 'Subpage',
        component: () =>
            import ('../views/Page.vue')
    },
    {
        path: '/medvirker/:consultantName-:consultantId',
        name: 'Consultant',
        component: () =>
            import ('../views/Consultant.vue')
    },
    {
        path: '/order/:orderId/:orderHash',
        name: 'Order',
        component: () =>
            import ('../views/Order.vue')
    },
    {
        path: '/opret-login',
        alias: ['/opret-login/virksomhed', '/opret-login/medvirker'],
        name: 'Create login',
        component: () =>
            import ('../views/LoginCreate.vue')
    }
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router